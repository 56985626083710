var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "div",
        {
          staticStyle: {
            display: "inline-block",
            "font-size": "14px",
            color: "#333"
          }
        },
        [
          _vm.single === "platform"
            ? [
                _c("span", [_vm._v("平台 ")]),
                _c(
                  "el-select",
                  {
                    staticStyle: { width: "279px", "margin-right": "30px" },
                    attrs: {
                      multiple: _vm.singleMultiSelect,
                      clearable: "",
                      placeholder: "可选择多个平台"
                    },
                    on: { change: _vm.handleChange },
                    model: {
                      value: _vm.platformSelected,
                      callback: function($$v) {
                        _vm.platformSelected = $$v
                      },
                      expression: "platformSelected"
                    }
                  },
                  _vm._l(_vm.platformList, function(item) {
                    return _c("el-option", {
                      key: item.idLabel,
                      attrs: { label: item.label, value: item.idLabel }
                    })
                  }),
                  1
                )
              ]
            : _vm.single === "client"
            ? [
                _c("span", [_vm._v("客户 ")]),
                _c(
                  "el-select",
                  {
                    staticStyle: { width: "279px", "margin-right": "30px" },
                    attrs: {
                      filterable: "",
                      remote: "",
                      clearable: "",
                      multiple: _vm.singleMultiSelect,
                      placeholder: "请输入用户名/手机号搜索，支持多选",
                      "remote-method": _vm.getClientList
                    },
                    on: { change: _vm.handleChange },
                    model: {
                      value: _vm.clientSelected,
                      callback: function($$v) {
                        _vm.clientSelected = $$v
                      },
                      expression: "clientSelected"
                    }
                  },
                  _vm._l(_vm.clientList, function(item) {
                    return _c("el-option", {
                      key: item.idLabel,
                      attrs: { label: item.label, value: item.idLabel }
                    })
                  }),
                  1
                )
              ]
            : _vm.single === "app"
            ? [
                _c("span", [_vm._v("应用 ")]),
                _c(
                  "el-select",
                  {
                    staticStyle: { width: "279px", "margin-right": "30px" },
                    attrs: {
                      filterable: "",
                      remote: "",
                      clearable: "",
                      multiple: _vm.singleMultiSelect,
                      placeholder: "请输入应用名称/ID搜索，支持多选",
                      "remote-method": _vm.getAppList
                    },
                    on: { change: _vm.handleChange },
                    model: {
                      value: _vm.appSelected,
                      callback: function($$v) {
                        _vm.appSelected = $$v
                      },
                      expression: "appSelected"
                    }
                  },
                  _vm._l(_vm.appList, function(item) {
                    return _c("el-option", {
                      key: item.idLabel,
                      attrs: { label: item.label, value: item.idLabel }
                    })
                  }),
                  1
                )
              ]
            : _vm._e()
        ],
        2
      ),
      _vm.radioFilters.length
        ? [
            _c(
              "div",
              {
                staticStyle: {
                  display: "inline-block",
                  "padding-right": "3px",
                  color: "#333",
                  "font-size": "14px"
                }
              },
              [
                _vm.radioFilters.length > 1
                  ? _c(
                      "el-select",
                      {
                        staticStyle: { width: "90px" },
                        on: { change: _vm.handleChange },
                        model: {
                          value: _vm.radioFilterSelected,
                          callback: function($$v) {
                            _vm.radioFilterSelected = $$v
                          },
                          expression: "radioFilterSelected"
                        }
                      },
                      _vm._l(_vm.radioFilters, function(item) {
                        return _c("el-option", {
                          key: item.name,
                          attrs: { label: item.label, value: item.name }
                        })
                      }),
                      1
                    )
                  : _c("span", [_vm._v(_vm._s(_vm.radioFilters[0].label))])
              ],
              1
            ),
            _vm.radioFilterSelected === "platform"
              ? _c(
                  "el-select",
                  {
                    staticStyle: { width: "200px" },
                    attrs: { clearable: "", placeholder: "请选择平台" },
                    on: { change: _vm.handleChange },
                    model: {
                      value: _vm.platformSelected,
                      callback: function($$v) {
                        _vm.platformSelected = $$v
                      },
                      expression: "platformSelected"
                    }
                  },
                  _vm._l(_vm.platformList, function(item) {
                    return _c("el-option", {
                      key: item.id,
                      attrs: { label: item.label, value: item.id }
                    })
                  }),
                  1
                )
              : _vm.radioFilterSelected === "client"
              ? _c(
                  "el-select",
                  {
                    staticStyle: { width: "200px" },
                    attrs: {
                      filterable: "",
                      remote: "",
                      clearable: "",
                      placeholder: "请输入用户名/手机号搜索",
                      "remote-method": _vm.getClientList
                    },
                    on: { change: _vm.handleChange },
                    model: {
                      value: _vm.clientSelected,
                      callback: function($$v) {
                        _vm.clientSelected = $$v
                      },
                      expression: "clientSelected"
                    }
                  },
                  _vm._l(_vm.clientList, function(item) {
                    return _c("el-option", {
                      key: item.id,
                      attrs: { label: item.label, value: item.id }
                    })
                  }),
                  1
                )
              : _vm.radioFilterSelected === "app"
              ? _c(
                  "el-select",
                  {
                    staticStyle: { width: "200px" },
                    attrs: {
                      filterable: "",
                      remote: "",
                      clearable: "",
                      placeholder: "请输入应用名称/ID搜索",
                      "remote-method": _vm.getAppList
                    },
                    on: { change: _vm.handleChange },
                    model: {
                      value: _vm.appSelected,
                      callback: function($$v) {
                        _vm.appSelected = $$v
                      },
                      expression: "appSelected"
                    }
                  },
                  _vm._l(_vm.appList, function(item) {
                    return _c("el-option", {
                      key: item.id,
                      attrs: { label: item.label, value: item.id }
                    })
                  }),
                  1
                )
              : _vm.radioFilterSelected === "sku"
              ? _c(
                  "el-select",
                  {
                    staticStyle: { width: "200px" },
                    attrs: {
                      filterable: "",
                      remote: "",
                      clearable: "",
                      placeholder: "请搜索SKU",
                      "remote-method": _vm.getSkuList
                    },
                    on: { change: _vm.handleChange },
                    model: {
                      value: _vm.skuSelected,
                      callback: function($$v) {
                        _vm.skuSelected = $$v
                      },
                      expression: "skuSelected"
                    }
                  },
                  _vm._l(_vm.skuList, function(item) {
                    return _c("el-option", {
                      key: item.id,
                      attrs: { label: item.label, value: item.id }
                    })
                  }),
                  1
                )
              : _vm._e()
          ]
        : _vm._e()
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }